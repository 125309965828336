<template>
    <div
        class="sale_stats_card tp_card"
    >
        <div class="sale_card_upper">
            <h5>{{title}}</h5>
            <!--
            <i class="fa-solid"
                :class=icon
                >
            </i>-->
        </div>
        <div class="sale_card_lower">
            <div v-for="item in stats" :key="item.title" class="big_commission_container">
                <p class="big_commission">
                    <span v-html="item.value">
                    </span>
                </p>
                <p class="big_commission_description">{{item.title}}</p>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    import { formatAmount } from "@/utils/general.js"

    export default {
        props: {
            title: String,
            icon: String,
            stats: Object,
            isYtd: Boolean,
        },
        methods: {
            formatAmount,
        },
        computed: {
            ...mapGetters("period", [
                "periodContract",
            ]),
            formatSaleValue() {
                if (this.stats == null) {
                    return "";
                }
                return formatAmount({
                    amount: this.stats.period_sale_value,
                    roundByThousand: true,
                    doColor: false,
                    hideZero: false,
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/SalesStats.scss";
</style>
