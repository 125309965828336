<template>
    <div class="period_info_section">
        <div class="left_part">
            <vSelect
                class="tp_dropdown period_dropdown"
                v-if="!hidePeriodSwapper"
                :options="availablePeriods" label="full_name"
                v-model="selectedPeriodStart"
                :reduce="period => period.start"
                :clearable="false"
                :searchable="false"
                placeholder="asasdd"
            >
                <template #option="{ display_name } ">
                    <div class="seller_name_container">
                        <em>{{display_name}}</em>
                    </div>
                </template>
                <template #selected-option="{ display_name }">
                    {{display_name}}
                </template>
            </vSelect>
        </div>
        <div class="mid_part">
            <vSelect
                class="tp_dropdown seller_dropdown"
                v-if="userData?.role == 'manager' && !sellerSelectorHidden"
                :options="sellers" label="full_name"
                v-model="selectedSellerUuid"
                :reduce="seller => seller.user_uuid"
                :clearable="false"
                :searchable="false"
            >
                <template #option="{ full_name, first_name, last_name, profile_pic, user_uuid  } ">
                    <UserAvatar
                        class="user_avatar"
                        :picData="profile_pic"
                        :firstName="first_name"
                        :lastName="last_name"
                        :userUuid="user_uuid"
                        size="3rem"
                    >
                    </UserAvatar>
                    <div class="seller_name_container">
                        <em>{{  user_uuid != userData.user_uuid ? full_name : "Me (" + full_name + ")"  }} </em>
                    </div>
                </template>
                <template #selected-option="{ full_name, first_name, last_name, profile_pic, user_uuid  }">
                    <UserAvatar
                        class="user_avatar"
                        :picData="profile_pic"
                        :firstName="first_name"
                        :lastName="last_name"
                        :userUuid="user_uuid"
                        size="2.5rem"
                    >
                    </UserAvatar>
                    <div class="seller_name_container">
                        {{ user_uuid != userData.user_uuid ? full_name : "Me (" + full_name + ")" }}
                    </div>
                </template>
            </vSelect>
        </div>
        <div class="right_part">
            <b-button
                pill
                variant="primary"
                class="refresh_button button_with_icon_only"
                @click="refresh"
            >
                <i class="fa-solid fa-refresh"></i>
                <span></span>
            </b-button>
        </div>
        <!--
        <div class="period_info_outer">
            <div class="period_info_container" v-if="!hidePeriodSwapper">
                <div class="period_selector_container">
                    <i class="fa-solid fa-angles-left cursor_pointer" @click="prevPeriod"></i>
                    <span class="mx-3 period_display bold"> {{ formattedSelectedPeriod }} </span>
                    <i 
                        class="fa-solid fa-angles-right cursor_pointer"
                        @click="nextPeriod"
                        v-if="canGoToNextPeriod"
                        >
                    </i>
                    <i 
                        class="fa-solid fa-angles-right disabled"
                        v-else
                        >
                    </i>
                </div>
                <div
                    v-if="showPeriodStatus && currentPeriodStatus?.is_past_or_current && !periodConfirmHidden"
                    class="period_status_container"
                    >
                    <div class="period_status">
                        {{periodStatusState?.display}}
                    </div>
                    <div class="period_buttons_container">
                        <b-button
                            v-for="action in periodStatusState?.actions"
                            :key="action"
                            pill
                            variant="primary"
                            class="button_with_icon"
                            :class=action.btn_class
                            @click="doPeriodAction(action.action_name)"
                            >
                            <i
                                class="fa-solid"
                                :class=action.btn_icon
                                >
                            </i>
                            <span>{{" " + action.btn_text}}</span>
                        </b-button>
                        <b-modal
                            v-model="isConfirmReopenModalVisible"
                            title="Confirm reopening"
                            ok-title="Yes"
                            cancel-title="No"
                            @ok="doPeriodAction(SELLER_REOPEN)"
                            >
                            <p class="mb-0">If you reopen this period, your manager will need to approve it again. Are you sure?</p>
                        </b-modal>
                    </div>
                    <div 
                        v-if="fortnoxStatus?.display.length > 0"
                        class="period_fortnox_warning">
                        <i 
                            v-if="fortnoxStatus?.status == 'error'"
                            class="fa-solid fa-triangle-exclamation yellow">
                        </i>

                        <div 
                            v-if="fortnoxStatus?.status == 'pending'"
                            class="spinner-border" 
                            role="status">
                        </div>
                        <i 
                            v-if="fortnoxStatus?.status == 'ok'"
                            class="fa-solid fa-check green">
                        </i>
                        &nbsp;
                        <div>
                            {{fortnoxStatus.display}}
                        </div>
                    </div>
                </div>
                <div class="week_selector_container" v-if="!weekSelectorHidden">
                    <div class="btn-group" role="group">
                        <span>
                            <input
                                type="radio"
                                class="btn-check"
                                id="all_month_btn" 
                                :value="ENTIRE_PERIOD"
                                v-model="selectedWeek"
                                autocomplete="off"
                                :disabled="false"
                            >
                            <label
                                class="btn btn-outline-primary"
                                for="all_month_btn"
                            >
                                All month
                            </label>
                        </span>
                        <span v-for="week in weekSelectorItems" :key="week.weekNumber">
                            <input
                                type="radio"
                                class="btn-check"
                                :id="'week' + week.weekNumber" 
                                :value="week.start"
                                v-model="selectedWeek"
                                autocomplete="off"
                                :disabled="false"
                                v-if="!week.future"
                            >
                            <label
                                class="btn btn-outline-primary"
                                :for="'week' + week.weekNumber"
                                v-if="!week.future"
                            >
                                {{ week.display }}
                            </label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        -->
        <!--
        <div class="period_contract_outer" v-if="!hidePeriodSwapper && !contractHidden">
            <p class="period_contract_title">Contract</p>
            <router-link class="display_contract" to="/contracts">
                {{periodContract?.name}}
            </router-link>
        </div>-->
    </div>
</template>
<script>
    import axios from 'axios';

    import vSelect from "vue-select";
    import { addMonths, startOfMonth, /*endOfMonth,*/ startOfWeek, addWeeks, endOfWeek, /*addDays,*/ isBefore, getISOWeek, isAfter } from 'date-fns';

    import { format, parseISO } from 'date-fns';
    import { mapGetters, mapActions } from 'vuex';

    import UserAvatar from '@/components/UserAvatar.vue';
    import * as Keys from '@/utils/keys';
    import * as Const from '@/utils/const';

    export default {
        components: {
            vSelect,
            UserAvatar,
        },
        props: {
            showPeriodStatus: Boolean,
            hidePeriodSwapper: Boolean,
            sellerSelectorHidden: Boolean,
            weekSelectorHidden: Boolean,
            contractHidden: Boolean,
            periodConfirmHidden: Boolean,
        },
        data() {
            return {
                Keys,
                Const,
                //selectedPeriodIndex: null,
                //periods: null,

                sellers: [],
                selectedSellerUuid: '',
                selectedPeriodStart: '',
                ENTIRE_PERIOD: "entire_period",
                selectedWeek: null,

                isConfirmReopenModalVisible: false,

                periodActionPending: false,
                periodActionComplete: false,
            };
        },
        methods: {
            ...mapActions("period", [
                "changeSeller",
                "changePeriod",
                "changeWeek",
                "triggerRefresh",
                "changePeriodNotEditableReason",
                "changePeriodContract",
                "changePeriodByStart",
                "nextPeriod",
                "prevPeriod",
            ]),
            async fetchSellers() {
                try {
                    const response = await axios.get('/api/get_my_sellers');
                    this.sellers = response.data;
                    if (this.sellers.length > 0) {
                        // Preselect the first seller's user_uuid
                        if (this.storedSelectedSellerUuid == null) {
                            this.selectedSellerUuid = this.sellers[0].user_uuid;
                        } else {
                            // Check that the stored seller exists among your sellers. This might be an issue when changing user without clearing LocalStorage
                            const sellerExists = this.sellers.some(seller => seller.user_uuid == this.storedSelectedSellerUuid);
                            if (sellerExists) {
                                this.selectedSellerUuid = this.storedSelectedSellerUuid;
                            } else {
                                this.selectedSellerUuid = this.sellers[0].user_uuid;
                            }
                        }
                    } else {
                        console.log("Got no sellers in fetchSellers");
                        throw Error("No sellers found");
                    }
                    //this.fetchSales();
                } catch (error) {
                    console.log("Error fetching sellers", error);
                    this.$toastr.warning("Error fetching sellers");
                }
            },
            showConfirmReopen() {
                this.isConfirmReopenModalVisible = true;
            },
            hideConfirmReopen() {
                this.isConfirmReopenModalVisible = false;
            },
            refresh() {
                this.triggerRefresh();
            },
        },
        computed: {
            ...mapGetters("auth", ["userData"]),
            ...mapGetters("period", ["periodContract", "refreshTrigger", "selectedPeriod", "availablePeriods"]),
            ...mapGetters("period", {
                storedSelectedSellerUuid: "selectedSellerUuid",
            }),
            formattedSelectedPeriod() {
                if (this.selectedPeriod == null) {
                    return "";
                }
                const date = parseISO(this.selectedPeriod.start);
                return format(date, 'MMM yyyy'); // Format date as "February 2024"
                /*
                if (this.periods == null) {
                    return '';
                }
                if (this.periods.length > 0 && this.selectedPeriodIndex < this.periods.length) {
                    const period = this.periods[this.selectedPeriodIndex];
                    const date = parseISO(period.start); // Convert string to Date object
                    return format(date, 'MMM yyyy'); // Format date as "February 2024"
                }

                return '';*/
            },
            sellerOptions() {
                return this.sellers.map(seller => ({ key: seller.user_uuid, name: seller.name }));
            },
            currentPeriodStatus() {
                if (this.selectedPeriod == null) {
                    return null;
                }
                if (this.periodStatuses == null) {
                    return null;
                }

                return this.periodStatuses[this.selectedPeriod.start];
            },
            selectedSeller() {
                const seller = this.sellers.find(seller => seller.user_uuid === this.selectedSellerUuid);
                return seller;
            },
            weekSelectorItems() {
                if (this.weekSelectorHidden) {
                    return [];
                }
                if (this.selectedPeriod == null) {
                    return [];
                }

                let startOfCurrentMonth = startOfMonth(this.selectedPeriod.start_date);
                let startOfNextMonth = addMonths(startOfCurrentMonth, 1);

                const weeks = [];

                const now = new Date();

                for (let date = startOfCurrentMonth; isBefore(date, startOfNextMonth); date = addWeeks(date, 1)) {
                    const currentWeekStart = startOfWeek(date, { weekStartsOn: 1 });
                    const end = endOfWeek(date, { weekStartsOn: 1 });
                    const isCurrent = now > currentWeekStart && now < end
                    let display = "Week " + getISOWeek(date);
                    if (isCurrent) {
                        display = "This week";
                    }
                    weeks.push({
                        // start: currentWeekStart,
                        start: format(currentWeekStart, Const.DATE_FORMAT),
                        // end: endOfWeek(date, { weekStartsOn: 1 }),
                        weekNumber: getISOWeek(date),
                        future: isAfter(currentWeekStart, now),
                        display: display,
                    });
                }
                return weeks;
            },
            canGoToNextPeriod() {
                if (this.selectedPeriod == null) {
                    return true;
                }
                if (this.canGoToFuturePeriods) {
                    return true;
                }
                const now = new Date();
                const currentIsNow = now >= this.selectedPeriod.start_date && now <= this.selectedPeriod.end_date;
                return !currentIsNow;
            },
            canGoToFuturePeriods() {
                return this.$route.path != "/scoreboard";
            },
        },
        watch: {
            selectedSellerUuid(newUuid) {
                this.changeSeller(newUuid);
                // this.fetchPeriodStatus();

                this.periodActionComplete = false;
            },   
            storedSelectedSellerUuid(newUuid) {
                // This is to deal with external changes of seller uuid
                if (newUuid != this.selectedSellerUuid) {
                    this.selectedSellerUuid = newUuid;
                }
            },
            selectedPeriodStart(newStartStr) {
                this.changePeriodByStart(newStartStr);
            },
            /*
            selectedPeriod() {
                //this.changePeriod(this.periods[newPeriodIndex]);
                //this.$emit('on-period-changed', { selectedPeriod: this.periods[newPeriodIndex] });
                this.fetchPeriodStatus();

                this.periodActionComplete = false;

                this.selectedWeek = this.ENTIRE_PERIOD;
            },*/
            selectedWeek(newWeekStart) {
                this.changeWeek(newWeekStart);
            },
            // Fetch sellers on login
            userData(newVal) {
                if (newVal != null) {
                    this.fetchSellers();
                }
            },
            refreshTrigger() {
                this.fetchSellers();
            },
        },
        mounted() {
            this.selectedWeek = this.ENTIRE_PERIOD;
            this.selectedPeriodStart = this.selectedPeriod.start;
        },
    }
</script>
<style>
    @import "vue-select/dist/vue-select.css";
</style>
<style lang="scss">
    @import "@/assets/styles/PeriodSelector.scss";
</style>
