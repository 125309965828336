<template>
    <div class="content_container">
        <AppHeader
            v-if="!sidebarsHidden"
            @nav-btn-clicked="toggleNav"
            :navIsOpen="navIsOpen"
            :asideIsOpen="asideIsOpen"
            @aside-btn-clicked="toggleAside"
        />
        <div class="content">
            <NavSidebar
                v-if="!sidebarsHidden"
                 class="nav_sidebar"
                :class="{'nav_is_open': navIsOpen}"
                @nav-item-clicked="closeNav"
            />
            <main
                v-bind:class="{'no_margin_left_top_right' : $route.path === '/login'}"
                @click="mainClicked"
                >
                <PeriodSelector
                    :showPeriodStatus=true
                    v-show="periodSelectorVisible"
                    :hidePeriodSwapper=shouldHidePeriodSwapper
                    :sellerSelectorHidden=sellerSelectorHidden
                    :weekSelectorHidden=weekSelectorHidden
                    :contractHidden=contractHidden
                    :periodConfirmHidden=periodConfirmHidden
                />
                <router-view></router-view>
            </main>
            <AsidePanel
                :class="{'aside_is_open': asideIsOpen}"
                v-if="!sidebarsHidden"
            ></AsidePanel>
        </div>
    </div>
</template>

<script>
    import AppHeader from './components/AppHeader.vue'
    import NavSidebar from './components/NavSidebar.vue'
    import AsidePanel from './components/AsidePanel.vue'
    import PeriodSelector from '@/components/PeriodSelector.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        components: {
            AppHeader,
            NavSidebar,
            PeriodSelector,
            AsidePanel,
        },
        data() {
            return {
                //isDarkMode: localStorage.getItem("theme") == null || localStorage.getItem('theme') === 'dark',
                navIsOpen: false,
                asideIsOpen: false,
            }
        },
        methods: {
            ...mapActions("auth", ["logout"]),
            toggleNav() {
                this.navIsOpen = !this.navIsOpen;
            },
            closeNav() {
                this.navIsOpen = false;
            },
            toggleAside() {
                this.asideIsOpen = !this.asideIsOpen;
            },
            mainClicked() {
                this.navIsOpen = false;
                this.asideIsOpen = false;
            },
        },
        computed: {
            ...mapGetters("theme", ["isDarkMode"]),
            ...mapGetters("auth", ["isAuthenticated", "userData", "authStatus"]),
            ...mapGetters("products", ["userProducts"]),
            periodSelectorVisible() {
                const visibleOn = ["/sales", "/dashboard", "/stats", "/contracts", "/payroll", "/scoreboard"];
                return visibleOn.some(item => this.$route.path === item);
            },
            shouldHidePeriodSwapper() {
                let hide = this.$route.path == "/contracts"
                return hide;
            },
            sellerSelectorHidden() {
                const hideOn = ["/payroll", "/scoreboard"];
                return hideOn.some(item => this.$route.path === item);
            },
            weekSelectorHidden() {
                const showOn = ["/scoreboard"];
                return !showOn.includes(this.$route.path);
            },
            contractHidden() {
                const hideOn = ["/payroll", "/scoreboard"];
                return hideOn.some(item => this.$route.path == item);
            },
            periodConfirmHidden() {
                const hideOn = ["/payroll", "/scoreboard"];
                return hideOn.some(item => this.$route.path == item);
            },
            sidebarsHidden() {
                // Checking isAuthenticated prevents the nav and header from flashing on initial load,
                // the hideOn-check below hides the nav and sidebar in case the user is logged in but
                // clicks on a reset password link and comes to the login page.
                if (!this.isAuthenticated) {
                    return true;
                }
                const hideOn = ["/login"];
                return hideOn.some(item => this.$route.path == item);
            },
        },
        async mounted() {
            document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
            // This "dark" class on <html> is needed for datatable's dark mode
            if (this.isDarkMode) {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }

            if (localStorage.getItem('access-token')) {
                try {
                    await this.$store.dispatch('auth/fetchUserData');
                    // Further actions upon success
                } catch (error) {
                    // TODO redirect to login?
                    console.error('Failed to fetch user data:', error);
                    // Error handling, e.g., redirect to login
                }
            }

            console.log("this.authStatus: ", this.authStatus);
            //if (this.authStatus != "logged_out") {
            // TODO check if this is broken when state is wiped
            //if (this.authStatus == "success") {
                //await this.$store.dispatch('products/fetchProducts');
            //}
        },
        watch: {
            async userData(newVal) {
                console.log("user data changed");
                if (newVal == null && this.authStatus != "logged_out") {
                    console.log("refetching user data");
                    try {
                        await this.$store.dispatch('auth/fetchUserData');
                        console.log("Successfully refreshed user data");
                    } catch (error) {
                        console.error("Error trying to refresh userData", error);
                        this.logout({ router: this.$router });
                        //store.dispatch("auth/logout", { router: router });
                    }
                }
            },
            /*
            async authStatus(newVal) {
            if (newVal == "success") {
                await this.$store.dispatch('products/fetchProducts');
            }
            },
            async userProducts(newVal) {
                console.log("USER PRODUCTS CHANGED")
                if ((newVal == null  || newVal.length === 0) && this.authStatus != "logged_out") {
                    console.log("refetching user PRODUCTS");
                    await this.$store.dispatch('products/fetchProducts');
                }
            }*/
        },
    }

</script>

<style lang="scss">
#app {
    //font-family: 'Signika', sans-serif;
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--primary_text_color);

    .content_container {
        display: flex;
        flex-direction:column;
        min-height: 100vh;
        margin: 0;

        $header_height: 4rem;
        $nav_width: 250px;
        $aside_panel_width: 18rem;
        .header {
            flex: 0 1 auto;

            /* The z-index does seem to work setting relative,
               but apparently it's not supposed to */
            position: fixed;
            left: $nav_width;
            width: calc(100% - $nav_width);
            z-index: 100;
            height: $header_height;
            background: var(--panel_background_color);
            justify-content: space-between;
            padding-left: $inner_component_padding;

            @include mobile {
                width: 100%;
                left: 0;
            }

        }
        $slide_in_delay: 0.2s;
        .aside_panel {
            margin-top: $header_height;
            width: $aside_panel_width;
            height: calc(100vh - $header_height);
            transition: right $slide_in_delay ease-in-out;
            background: var(--panel_background_color);
            @include tablet_and_smaller {
                position: fixed;
                right: -$aside_panel_width;
                &.aside_is_open {
                    right: 0;
                }
            }
        }
        .content {
            display: flex;
            flex: 1;

            main {
                flex-grow: 1;
                overflow-y: auto;
                margin-left: $nav_width;
                margin-top: $header_height;
                //padding-left: $lesser_component_padding;
                //padding-right: $lesser_component_padding;
                margin-right: $aside_panel_width;

                @include tablet_and_bigger {
                }
                @include mobile {
                    margin-left: 0;
                }
                @include tablet_and_smaller {
                    margin-right: 0;
                }
            }

            .nav_sidebar {
                background: var(--panel_background_color);
                //top: $header_height;
                width: $nav_width;
                flex-shrink: 0;
                z-index: 90;
                position: fixed;
                top: 0;
                left: 0;
                height: calc(100vh);
                transition: left $slide_in_delay ease-in-out;
                @include mobile {
                    //position: absolute;
                    left: -$nav_width;
                    &.nav_is_open {
                        left: 0;
                    }
                }
            }
        }

    }

    .no_margin_left_top_right {
        margin-left: 0 !important;
        margin-top: 0 !important;
        margin-right: 0 !important;
    }
}
</style>
