<template>
    <div class="sale_stats_container">
        <SalesStatsCard
            :title="monthName + ' sales'"
            icon="fa-sack-dollar"
            :stats=thisMonthPerformanceStats
            v-loading="currentPeriodStats == null"
        />
        <SalesStatsCard
            :title="monthName + ' payout'"
            icon="fa-sack-dollar"
            :stats=thisMonthPayoutStats
            v-loading="currentPeriodStats == null"
        />
        <SalesStatsCard
            title="This year"
            icon="fa-calendar-days"
            :stats=thisYearStats
            v-loading="currentPeriodStats == null"
        />
        <BonusStatsCard
            v-loading="currentPeriodStats == null"
        />
    </div>
</template>
<script>
    import axios from 'axios';
    import { format } from 'date-fns';
    import { mapGetters, mapActions } from 'vuex';

    import SalesStatsCard from '@/components/SalesStatsCard.vue';
    import BonusStatsCard from '@/components/BonusStatsCard.vue';

    import { formatAmount } from "@/utils/general.js"
    import * as Keys from '@/utils/keys';

    export default {
        components: {
            SalesStatsCard,
            BonusStatsCard,
        },
        props: {
            title: String,
            selectedPeriod: Object,
        },
        data() {
            return {
                KEY_PERIOD_COMMISSION: "period_commission",
                KEY_PERIOD_N_NEW: "period_n_new",
                KEY_PERIOD_N_CUSTOMERS: "period_n_customers",
                KEY_PERIOD_SALE_VALUE: "period_sale_value",
                periodsStats: {},
                ytdStats: {},
                selectedMonth: "",
            }
        },
        methods: {
            ...mapActions("period", ["changePeriodEarned"]),
            formatAmount,
            async fetchStats(sellerUuid, periodStart) {
                if (!sellerUuid || !periodStart) {
                    console.log("fetchStats called with invalid seller or period. Seller:", sellerUuid, "period:", periodStart);
                    return;
                }
                try {
                    const response = await axios.get("/api/get_stats_for_period", {
                        params: {
                            "seller_uuid": sellerUuid,
                            "period": periodStart,
                        }
                    });
                    this.periodsStats[periodStart] = response.data[Keys.KEY_CURRENT_PERIOD];
                    this.changePeriodEarned(response.data[Keys.KEY_CURRENT_PERIOD][Keys.KEY_PERIOD_COMMISSION_EARNED]);
                    this.ytdStats[periodStart] = response.data["ytd"];
                } catch (error) {
                    console.error("Failed to fetch stats", error);
                    this.$toastr.error("Error fetching stats");
                }
            },
        },
        computed: {
            ...mapGetters("period", [
                "periodContract",
            ]),
            monthName() {
                if (this.selectedPeriod == null) {
                    return "";
                }
                return format(this.selectedPeriod.start, "MMMM");
            },
            periodYtdStats() {
                if (!this.ytdStats || !this.selectedPeriod || !this.ytdStats[this.selectedPeriod.start]) {
                    return null;
                }
                return this.ytdStats[this.selectedPeriod.start];
            },
            currentPeriodStats() {
                if (!this.periodsStats || !this.selectedPeriod || !this.ytdStats[this.selectedPeriod.start]) {
                    return null;
                }
                return this.periodsStats[this.selectedPeriod.start];
            },
            thisMonthPerformanceStats() {
                let nNewSales = 0;
                let nCustomers = 0;
                let amountEarned = 0;
                let soldFor = 0;
                if (this.currentPeriodStats != null){
                    //return [];
                    nNewSales = this.currentPeriodStats.period_n_new;
                    nCustomers = this.currentPeriodStats.period_n_customers;
                    amountEarned = this.formatAmount({ amount: this.currentPeriodStats[Keys.KEY_PERIOD_COMMISSION_EARNED], roundByThousand: false, doColor: true, hideZero: false, });
                    soldFor = this.formatAmount({ amount: this.currentPeriodStats.period_sale_value, roundByThousand: true, doColor: false, hideZero: false, });
                }
                const stats = [];
                //<span v-html="formatAmount({ amount: item.value, roundByThousand: false, doColor: true, hideZero: false, })">
                stats.push({
                    "title": "sales",
                    "value": nNewSales,
                });
                stats.push({
                    "title": "customers",
                    "value": nCustomers,
                });
                stats.push({
                    "title": "earned in " + this.monthName,
                    "value": amountEarned,
                });
                stats.push({
                    "title": "sold for",
                    "value": soldFor,
                });
                return stats;
            },
            thisMonthPayoutStats() {
                let commissionPayout = 0;
                let baseSalary = 0;
                let totalPayout = 0;
                if (this.currentPeriodStats != null) {
                     commissionPayout = formatAmount({ amount: this.currentPeriodStats?.period_commission, roundByThousand: false, doColor: false, hideZero: false, });
                     baseSalary = formatAmount({ amount: this.periodContract?.base_salary, roundByThousand: false, doColor: false, hideZero: false, });
                     totalPayout = formatAmount({ amount: this.periodContract?.base_salary + this.currentPeriodStats?.period_commission, roundByThousand: false, doColor: true, hideZero: false, });
                }
                const stats = [];
                stats.push({
                    "title": "commissions payout",
                    "value": commissionPayout,
                });

                stats.push({
                    "title": "base salary",
                    "value": baseSalary,
                });

                stats.push({
                    "title": "total payout in " + this.monthName,
                    "value": totalPayout,
                });

                /*
                formatAmount({
                        amount: stats?.period_commission + ,
                        roundByThousand: false, doColor: true, hideZero: false, })*/
                return stats;
            },
            thisYearStats() {
                let nNew = 0;
                let nCustomers = 0;
                let soldFor = 0;
                if (this.periodYtdStats != null) {
                    nNew = this.periodYtdStats.period_n_new;
                    nCustomers = this.periodYtdStats.period_n_customers;
                    soldFor = this.formatAmount({ amount: this.periodYtdStats.period_sale_value, roundByThousand: true, doColor: false, hideZero: false, });
                }
                const stats = [];
                stats.push({
                    "title": "sales",
                    "value": nNew,
                });
                stats.push({
                    "title": "customers",
                    "value": nCustomers,
                });
                stats.push({
                    "title": "sold for",
                    "value": soldFor,
                });
                // TODO show commissions earned so far
                return stats;
            },
        },
        mounted() {
        }
    };

</script>
<style lang="scss" scoped>
    @import "@/assets/styles/SalesStats.scss";
</style>
