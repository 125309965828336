<template>

    <Popper
        arrow
        placement="bottom"
        offsetSkid="-200"
        @open:popper="onPopperOpen"
        @close:popper="onPopperClose"
        >
        <div class="notification_button_inner_container">
            <i class="fa-regular fa-bell"></i>
            <span v-if="nNewEvents > 0" class="notifications_badge">{{nNewEvents}}</span>
        </div>
        <template #content = "{ close }">
            <div class="dropdown_container">
                <h5>Notifications</h5>
                <!--check: {{lastEventsCheck}}-->
                <hr class="hr hr-blurry" />
                <ul v-if="filteredEvents.length > 0">
                    <li
                        v-for="event in filteredEvents"
                        :key="event.event_uuid"
                        class="notification_card"
                        @click="onEventClicked(event, close)"
                        >
                        <!-- <div class="avatar_container"> -->
                        <!--     <img -->
                        <!--         v-if="profilePics[event.performer.user_uuid]" -->
                        <!--         alt="Profile picture" :src="profilePics[event.performer.user_uuid]" -->
                        <!--         > -->
                        <!--     <b-avatar -->
                        <!--         v-else -->
                        <!--         :text="getInitials({firstName: event.performer.first_name, lastName: event.performer.last_name})" -->
                        <!--         size="5rem" -->
                        <!--         :style="{background: strToColor(event.performer.user_uuid) + '!important'}" -->
                        <!--         > -->
                        <!--     </b-avatar> -->
                        <!--     <i v-if="this.lastEventsCheck == null || parseISO(event.created_date) > this.lastEventsCheck" -->
                        <!--         class="fa-solid fa-circle is_new_indicator" -->
                        <!--         > -->
                        <!--     </i> -->
                        <!--     <i class="fa-solid action_indicator" :class=getEventInfo(event).icon> </i> -->
                        <!-- </div> -->
                        <UserAvatar
                            :picData="profilePics[event.performer.user_uuid]"
                            :firstName="event.performer.first_name"
                            :lastName="event.performer.last_name"
                            :userUuid="event.performer.user_uuid"
                            :actionIconClass="getEventInfo(event).icon"
                            :showNewIndicator="this.lastEventsCheck == null || parseISO(event.created_date) > this.lastEventsCheck"
                            size="5rem"
                        >
                        </UserAvatar>
                        <div class="event_text">
                            <p class="event_explanation" v-html="getEventInfo(event).text"></p>
                            <p class="event_time" v-html="formatTimeSince({ datetime: parseISO(event.created_date) })"></p>
                        </div>
                        <div class="event_icon">
                        </div>
                    </li>
                </ul>
                <p class="no_recent_activity" v-else> No recent activity</p>
            </div>
        </template>
    </Popper>
</template>



<script>
    //import { defineComponent } from "vue";
    import Popper from "vue3-popper";
    import {  parseISO } from 'date-fns';
    import { mapGetters, mapActions } from "vuex";
    import axios from "axios";

    import { getInitials, formatTimeSince } from "@/utils/general.js"

    import UserAvatar from '@/components/UserAvatar.vue';

    export default {
        name: "NotificationsPanel",
        components: {
            Popper,
            UserAvatar,
        },
        data() {
            return {
                events: [],
                lastEventsCheck: null,
                lastOpenTime: null,
                profilePics: {},
            };
        },
        methods: {
            ...mapActions("period", ["changeSeller", "changePeriodByStart"]),
            getInitials,
            formatTimeSince,
            parseISO,
            async fetchEvents() {
                try {
                    const response = await axios.get("/api/get_events");
                    this.events = response.data.events;
                    this.profilePics = response.data.profile_pics;

                    // Will be null when user has never opened the notification panel before
                    if (response.data.last_events_check != null) {
                        this.lastEventsCheck = parseISO(response.data.last_events_check);
                    }
                } catch (error) {
                    console.log("Error fetching events", error);
                    this.$toastr.warning("Error fetching events");
                }
            },
            getEventInfo(event) {
                let actionText = "";
                let icon = "";
                if (event.event_type == "seller_confirm") {
                    actionText = "confirmed";
                    icon = "fa-check"
                } else if (event.event_type == "seller_revoke") {
                    actionText = "revoked their confirmation of ";
                    icon = "fa-xmark"
                } else if (event.event_type == "seller_reopen") {
                    actionText = "repoened";
                    icon = "fa-xmark"
                } else if (event.event_type == "manager_attest") {
                    actionText = "approved";
                    icon = "fa-check"
                } else if (event.event_type == "manager_decline") {
                    actionText = "declined";
                    icon = "fa-xmark"
                } else if (event.event_type == "manager_revoke") {
                    actionText = "revoked their approval of ";
                    icon = "fa-xmark"
                } else {
                    return "";
                }
                const text = `${event.performer.first_name} ${event.performer.last_name} has ${actionText}
                        <strong>${event.period_name}</strong>`;
                return {
                    text: text,
                    icon: icon,
                }
            },
            onEventClicked(event, closeFnc) {
                this.changeSeller(event.period_owner_uuid);
                this.changePeriodByStart(event.period_start);
                this.$router.push('/sales');
                closeFnc();
            },
            async onPopperOpen() {
                try {
                    const response = await axios.post("/api/register_event_check");
                    this.lastOpenTime = response.data.last_events_check;
                } catch (error) {
                    console.log("Error fetching registering event check", error);
                    // this.$toastr.warning("Error fetching events");
                }
            },
            async onPopperClose() {
                this.lastEventsCheck = this.lastOpenTime;
            }
        },
        computed: {
            ...mapGetters("auth", ["authStatus", "userData"]),
            ...mapGetters("period", ["refreshTrigger"]),
            filteredEvents() {
                if (this.userData == null || this.events == null) {
                    return [];
                }
                return this.events.filter(event => event.performer.user_uuid != this.userData.user_uuid);
            },
            nNewEvents() {
                if (this.lastEventsCheck == null) {
                    return this.filteredEvents.length;
                }
                const newEvents = this.filteredEvents.filter(event => parseISO(event.created_date) > this.lastEventsCheck);
                return newEvents.length;
            },
        },
        mounted() {
            //document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
            this.fetchEvents();
        },
        watch: {
            // authStatus(newVal) {
            //     // if (newVal == "success") {
            //     //     this.fetchEvents();
            //     // }
            // },
            refreshTrigger() {
                this.fetchEvents();
            },
        }
    };
</script>
<style lang="scss" scoped>
    .notification_button_inner_container {
        position: relative;
        i {
            /*
            padding: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                background: var(--faded_text_color);
            }*/
            position: relative;
        }
        .notifications_badge {
            position: absolute;
            font-size: 1rem;
            top: 10%;
            left: 10%;
            background: $red;
            border-radius: 50%;
            line-height: 80%;
            color: black;
            padding: 0.15rem;
            pointer-events: none;
            /*@include shadow;*/
        }
    }
    .dropdown_container {
        h5 {
            margin-top: $lesser_component_padding;
            margin-left: $lesser_component_padding;
            line-height: 100%;
            font-size: 1.2rem;
            font-weight: 400;
        }
        width: 400px;
        ul {
            max-height: 60vh;
            overflow-y: scroll;
            list-style: none;
            @include nice-scrollbar;
            padding: 0;
            .notification_card {
                padding: $lesser_component_padding;
                display: flex;
                margin-top: 0.2rem;
                cursor: pointer;
                /*
                .avatar_container {
                    position: relative;
                    img {
                        width: 5rem;
                        height: 5rem;
                        border-radius: 50%;
                    }
                    .is_new_indicator {
                        position: absolute;
                        top: 5%;
                        left: 5%;
                        color: $red;
                        font-size: 1rem;
                        opacity: 0.9;
                    }
                    .action_indicator {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        font-size: 2rem;
                    }
                }*/
                .event_text {
                    display: flex;
                    flex-direction: column;
                    font-size: 1rem;
                    justify-content: space-between;
                    padding-left: $lesser_component_padding;
                    padding-right: $lesser_component_padding;
                    .event_explanation {
                        margin: 0;
                    }
                    .event_time {
                        color: var(--faded_text_color);
                        margin: 0;
                    }
                }
                .event_icon {
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    background: var(--secondary_background_color);
                }
            }
        }
        .no_recent_activity {
            font-size: 1rem;
            text-align: center;
        }
    }


</style>
<style>
    :root, [data-theme="dark"] {
        --popper-theme-background-color: var(--third_background_color);
        --popper-theme-background-color-hover: var(--third_background_color);
        --popper-theme-text-color: var(--primary_text_color);;
        --popper-theme-border-width: 0px;
        --popper-theme-border-style: solid;
        --popper-theme-border-radius: 6px;
        --popper-theme-padding: 0;
        --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
        /*
        --popper-theme-background-color: #333333;
        --popper-theme-background-color-hover: #333333;
        --popper-theme-text-color: #ffffff;
        --popper-theme-border-width: 0px;
        --popper-theme-border-style: solid;
        --popper-theme-border-radius: 6px;
        --popper-theme-padding: 0;
        --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);*/
}
</style>
