<template>
    <h5 class="status_title">Payout approval flow</h5>
    {{/* Making the container hidden when there's no data fixes an annoying glitch where isManagersPeriod will return false when swapping users */ ''}}
    <div
        class="stage_indicator_container"
        v-if="Object.keys(periodStatusState).length > 0"
    >
        <hr class="horizontal_line">
        <PeriodStatusBall
            v-if="isManagersPeriod"
            :stageNumber="0"
            :currentStage="periodStatusState?.[KEY_CURRENT_STAGE]"
            stageName="Self-review"
            description="You review your sales and confirm the period. Since you are a manager, no additional approval is needed."
        >
        </PeriodStatusBall>
        <PeriodStatusBall
            v-else
            :stageNumber="0"
            :currentStage="periodStatusState?.[KEY_CURRENT_STAGE]"
            stageName="Seller"
            description="Seller reviews their sales and then confirms the period."
        >
        </PeriodStatusBall>
        <PeriodStatusBall
            :stageNumber="1"
            :class="{'invisible': isManagersPeriod}"
            :currentStage="periodStatusState?.[KEY_CURRENT_STAGE]"
            stageName="Manager"
            description="Manager approves the confirmed sales to finalize this period's commission payout."
        >
        </PeriodStatusBall>
        <PeriodStatusBall
            :stageNumber="2"
            :currentStage="periodStatusState?.[KEY_CURRENT_STAGE]"
            stageName="Done"
            description="The approved commissions are processed for payout."
            :isLastStage="true"
        >
        </PeriodStatusBall>
    </div>
    <p class="period_status_display">{{periodStatusState.display}}</p>

    <div class="period_buttons_container">
        <b-button
            v-for="action in periodStatusState?.actions"
            :key="action"
            variant="primary"
            class="period_action_btn button_with_icon"
            :class=action.btn_class
            :disabled="periodActionPending"
            @click="doPeriodAction(action.action_name)"
        >
            <span v-if="!periodActionPending">{{action.btn_text}}</span>
            <div
                v-else
                class="spinner-border"
                role="status">
            </div>
            <!--
            <div
                class="spinner-border"
                role="status">
            </div>-->
        </b-button>
        <b-modal
            v-model="isConfirmReopenModalVisible"
            title="Confirm reopening"
            ok-title="Yes"
            cancel-title="No"
            @ok="doPeriodAction(SELLER_REOPEN)"
        >
            <p class="mb-0">If you reopen this period, your manager will need to approve it again. Are you sure?</p>
        </b-modal>
    </div>
    <b-modal
        v-model="isConfirmReopenModalVisible"
        title="Confirm reopening"
        ok-title="Yes"
        cancel-title="No"
        @ok="doPeriodAction(SELLER_REOPEN)"
    >
        <p class="mb-0">If you reopen this period, your manager will need to approve it again. Are you sure?</p>
    </b-modal>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';

    import * as Keys from '@/utils/keys.js';
    import * as Const from '@/utils/const.js';

    import PeriodStatusBall from '@/components/PeriodStatus/PeriodStatusBall.vue';

    import axios from "axios";

    export default {
        components: {
            PeriodStatusBall,
        },
        props: {
            /*
            modelValue: {
                type: String,
                default: '',
                required: true,
            },*/
        },
        data() {
            return {
                Keys,
                Const,
                SELLER_CONFIRM: "seller_confirm",
                MANAGER_ATTEST: "manager_attest",
                MANAGER_DECLINE: "manager_decline",
                SELLER_REVOKE: "seller_revoke",
                MANAGER_REVOKE: "manager_revoke",
                SELLER_REOPEN: "seller_reopen",
                ASK_SELLER_REOPEN: "ask_seller_reopen",
                MANAGER_SELF_APPROVE: "manager_self_approve",
                MANAGER_SELF_REOPEN: "manager_self_reopen",

                periodStatuses: {},

                periodActionPending: false,

                isConfirmReopenModalVisible: false,

                KEY_CURRENT_STAGE: "current_stage",
            };
        },
        mounted() {
            this.fetchPeriodStatus()
        },
        methods: {
            ...mapActions('auth', ["login"]),
            ...mapActions("period", [
                "changeSeller",
                "triggerRefresh",
                "changePeriodNotEditableReason",
                "changePeriodContract",
                "changePeriodByStart",
                "nextPeriod",
                "prevPeriod",
            ]),
            async doPeriodAction(actionName) {
                try {
                    if (actionName === this.ASK_SELLER_REOPEN) {
                        this.showConfirmReopen();
                        return;
                    }
                    const periodStart = this.selectedPeriod.start;
                    this.periodActionPending = true;
                    const response = await axios.post("/api/do_period_action", {
                        "seller_uuid": this.selectedSellerUuid,
                        "action": actionName,
                        "period": periodStart,
                    });
                    this.periodStatuses[periodStart] = response.data[Keys.KEY_PERIOD_STATUS];
                    // this.$emit("on-period-not-editable-changed", { reason: response.data.not_editable_reason });
                    this.changePeriodNotEditableReason(response.data[Keys.KEY_PERIOD_STATUS].not_editable_reason);
                    this.changePeriodContract(response.data[Keys.KEY_PERIOD_STATUS].contract);
                } catch (error) {
                    console.error('Failed to fetch period status:', error);
                    this.$toastr.warning("Error updating period");
                }
                this.periodActionPending = false;
            },
            async fetchPeriodStatus() {
                if (!this.selectedSellerUuid) {
                    console.log("Tried to fetch status with empty seller UUID");
                    return;
                }
                if (!this.selectedPeriod) {
                    console.log("Tried to fetch status for invalid period");
                    return;
                }
                const periodStart = this.selectedPeriod.start;

                // This prevents the wrong info from flashing quickly when swapping periods
                this.periodStatuses[periodStart] = null;
                try {
                    const response = await axios.get("/api/get_period_status", {
                        params: {
                            "seller_uuid": this.selectedSellerUuid,
                            "period": periodStart,
                        }
                    });
                    this.periodStatuses[periodStart] = response.data;
                    this.changePeriodNotEditableReason(response.data.not_editable_reason);
                    this.changePeriodContract(response.data.contract);
                } catch (error) {
                    this.$toastr.warning("Error fetching period status", error);
                    console.error('Failed to fetch period status:', error);
                }
            },
            showConfirmReopen() {
                this.isConfirmReopenModalVisible = true;
            },
            hideConfirmReopen() {
                this.isConfirmReopenModalVisible = false;
            },
        },
        computed: {
            ...mapGetters("auth", ["userData"]),
            ...mapGetters("period", ["selectedSellerUuid", "refreshTrigger", "selectedPeriod"]),
            periodStatusState() {
                let state = {};
                if (this.periodStatuses[this.selectedPeriod.start] == null) {
                    return state;
                }

                const periodStatus = this.periodStatuses[this.selectedPeriod.start];

                //if (this.userData?.role === "manager" && this.userData?.user_uuid === this.selectedSellerUuid) {
                if (this.userData?.role === "manager" && this.isManagersPeriod) {
                    const isOtherManagersPeriod = this.userData?.user_uuid !== this.selectedSellerUuid;
                    if (!periodStatus.approved_by_manager) {
                        state[this.KEY_CURRENT_STAGE] = 0;
                        if (isOtherManagersPeriod) {
                            state.display = "Waiting for manager to self-approve";
                        } else {
                            state.display = "Confirm period when it's complete";
                            const action = {
                                action_name: this.MANAGER_SELF_APPROVE,
                                btn_text: "Confirm",
                                btn_icon: "fa-check",
                                btn_class: "green_border",
                            }
                            state.actions = [action];
                        }
                    } else {
                        state[this.KEY_CURRENT_STAGE] = 2;
                        state.display = "Period approved and closed";
                        if (!isOtherManagersPeriod) {
                            const action = {
                                action_name: this.MANAGER_SELF_REOPEN,
                                btn_icon: "fa-xmark",
                                btn_text: "Unconfirm",
                                btn_class: "yellow_border",
                            }
                            state.actions = [action];
                        }
                    }
                    return state;
                }

                if (!periodStatus.approved_by_seller && !periodStatus.approved_by_manager) {
                    state[this.KEY_CURRENT_STAGE] = 0;
                    if (this.userData?.role === "manager") {
                        state.display = "Waiting for seller to confirm period";
                        state.actions = [];
                    } else {
                        state.display = "Confirm when period is complete";
                        const action = {
                            action_name: this.SELLER_CONFIRM,
                            btn_text: "Confirm",
                            btn_icon: "fa-check",
                            btn_class: "green_border",
                        }
                        state.actions = [action];
                    }
                } else if (periodStatus.approved_by_seller && !periodStatus.approved_by_manager) {
                    state[this.KEY_CURRENT_STAGE] = 1;
                    if (this.userData?.role === "manager") {
                        state.display = "Waiting for your approval";
                        const attest_action = {
                            action_name: this.MANAGER_ATTEST,
                            btn_text: "Approve",
                            btn_icon: "fa-check",
                            btn_class: "green_border",
                        }
                        const decline_action = {
                            action_name: this.MANAGER_DECLINE,
                            btn_text: "Decline",
                            btn_icon: "fa-xmark",
                            btn_class: "yellow_border",
                        }
                        state.actions = [attest_action, decline_action];
                    } else {
                        state.display = "Waiting for manager to approve";
                        const action = {
                            action_name: this.SELLER_REVOKE,
                            btn_icon: "fa-xmark",
                            btn_text: "Unconfirm",
                            btn_class: "yellow_border",
                        }
                        state.actions = [action];
                    }
                } else if (periodStatus.approved_by_seller && periodStatus.approved_by_manager) {
                    state[this.KEY_CURRENT_STAGE] = 2;
                    if (this.userData?.role === "manager") {
                        state.display = "Period is approved and closed";
                        const action = {
                            action_name: this.MANAGER_REVOKE,
                            btn_icon: "fa-xmark",
                            btn_text: "Unapprove",
                            btn_class: "yellow_border",
                        }
                        state.actions = [action];
                    } else {
                        state.display = "Period is approved by manager and is closed";
                        const action = {
                            action_name: this.ASK_SELLER_REOPEN,
                            btn_icon: "fa-xmark",
                            btn_text: "Reopen",
                            btn_class: "yellow_border",
                        }
                        state.actions = [action];
                    }
                }
                return state;
            },
            isManagersPeriod() {
                return this.periodStatuses?.[this.selectedPeriod.start]?.[Keys.KEY_IS_MANAGERS_PERIOD]
            },
            fortnoxStatus() {
                // TODO broken
                if (!this.userData
                    || !this.userData.organization_data[Keys.KEY_HAS_FORTNOX_INTEGRATION]
                    || !this.selectedPeriod
                    || !this.periodStatuses
                    || !this.selectedSeller
                ) {
                    return {
                        status: "",
                        display: "",
                    }
                }
                if (this.userData[Keys.KEY_ROLE] == Const.ROLE_MANAGER) {
                    // No real reason that "Fortnox not connected" and "Missing ID" couldn't be seen
                    // by non-managers, but there's also nothing they can do about it.
                    if (!this.userData.organization_data[Keys.KEY_IS_FORTNOX_LINKED]) {
                        return {
                            status: "error",
                            display: "Fortnox not connected",
                        }
                    }
                    if (!this.selectedSeller[Keys.KEY_FORTNOX_EMPLOYEE_ID]) {
                        return {
                            status: "error",
                            display: "Missing Fortnox employee ID",
                        }
                    }
                    if (this.periodActionPending) {
                        return {
                            status: "pending",
                            display: "Synchronizing with Fortnox...",
                        };
                    }
                }

                const periodStatus = this.periodStatuses[this.selectedPeriod.start];
                if (periodStatus != null) {
                    if (periodStatus[Keys.KEY_FORTNOX_SYNC_ERROR]) {
                        return {
                            status: "error",
                            display: "Fortnox synchronization failed",
                        };
                    }
                    if (periodStatus[Keys.KEY_APPROVED_BY_MANAGER]) {
                        return {
                            status: "ok",
                            display: "Synchronized with Fortnox",
                        };
                    }
                }
                return {
                    status: "",
                    display: "",
                };
            },
            selectedSeller() {
                const seller = this.sellers.find(seller => seller.user_uuid === this.selectedSellerUuid);
                return seller;
            },
        },
        watch: {
            selectedSellerUuid() {
                this.fetchPeriodStatus();
            },
            selectedPeriod() {
                this.fetchPeriodStatus();
            },
            refreshTrigger() {
                this.fetchPeriodStatus();
            }
        },
    };
</script>
<style lang="scss" scoped>

.status_title {
}
.period_status_display {
    color: var(--faded_text_color);
    text-align: center;
}

.stage_indicator_container {
    display: flex;
    position: relative;
    $ball_size: 1.8rem;
    .horizontal_line {
        position: absolute;
        background: var(--panel_border_color);
        height: 2px;
        z-index: 1;
        top: calc($ball_size / 2);
        $margin: 16.67%;
        $line_length: calc(100% - $margin*2);
        width: $line_length;
        left: $margin;
        margin: 0;
    }
}

.period_buttons_container {
    display: flex;
    margin-top: $lesser_component_padding;
    gap: $lesser_component_padding;
    .period_action_btn {
        display: flex;
        justify-content: center;
        height: 2rem;
        font-size: 1.2rem;
        .spinner-border {
            --bs-spinner-width: 1.5rem;
            --bs-spinner-height: 1.5rem;
        }
        flex: 1;
        background: var(--strong_highlight_color);
        color: var(--secondary_background_color);
        border: none;
        &:hover {
            border: none !important;
            //color: var(--);
            opacity: 0.8;

        }
    }
}

</style>
