
export const ROLE_MANAGER = "manager";
export const ROLE_SELLER = "seller";

export const ROLE_TO_FE_ROLE = {
    [ROLE_MANAGER]: "Manager",
    [ROLE_SELLER]: "Seller",
}

export const LADDER_BASE_SALE_VALUE = "sale_value";
export const LADDER_BASE_N_SALES = "n_sales";
export const LADDER_BASE_OPTIONS = [LADDER_BASE_SALE_VALUE, LADDER_BASE_N_SALES];

export const TYPE_RESIDUAL = "residual_sale"
export const TYPE_ONE_TIME = "one_time_sale"
export const TYPE_STAFFING = "staffing"

export const COMMISSION_TYPE_FIXED = "fixed";
export const COMMISSION_TYPE_RATE = "rate";

export const TRANSACTION_RESET_PASSWORD = "reset_password";
export const TRANSACTION_ACTIVATE_USER = "activate_user";


export const DATE_FORMAT = 'yyyy-MM-dd';
