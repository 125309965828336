<template>
    <!--
    <PeriodSelector
    :showPeriodStatus=false
    @on-refresh="handleRefresh"
    @on-seller-changed="handleSellerChanged"
    @on-period-changed="handlePeriodChanged"
    />-->
    <SalesStats
        ref="salesStatsRef"
        :selectedPeriod="selectedPeriod"
    />
    <div class="charts_container">
        <PieChart
            class="chart"
            :data="curPeriodCommissionPerCustomer"
            :doColorTotal="true"
            :title="`Commission payout in ${selectedPeriodMonthName}`"
            subtitle="Per customer"
        />
        <BarChart
            class="chart"
            :labels="periodNames"
            :data="monthlyValuePerCustomer"
            :selectedPeriodIndex="selectedPeriodIndex"
            :doColorTotal="false"
            title="Sale value"
            subtitle="Per customer"
        />
        <BarChart
            class="chart"
            :labels="periodNames"
            :data="monthlyCommissionPerCustomer"
            :selectedPeriodIndex="selectedPeriodIndex"
            :doColorTotal="true"
            title="Commission payouts"
            subtitle="Per customer"
        />

        <PieChart
            class="chart"
            :data="curPeriodCommissionPerProduct"
            :doColorTotal="true"
            :title="`Commission payout in ${selectedPeriodMonthName}`"
            subtitle="Per product"
        />

        <BarChart
            class="chart"
            :labels="periodNames"
            :data="monthlyValuePerProduct"
            :selectedPeriodIndex="selectedPeriodIndex"
            :doColorTotal="false"
            title="Sale value"
            subtitle="Per product"
        />
        <BarChart
            class="chart"
            :labels="periodNames"
            :data="monthlyCommissionPerProduct"
            :selectedPeriodIndex="selectedPeriodIndex"
            :doColorTotal="true"
            title="Commission"
            subtitle="Per product"
        />
        <!--
        <BarChart
            class="chart"
            :data="saleValuePerMonth"
            />-->
    </div>
</template>

<script>
    import axios from 'axios';
    import SalesStats from '@/components/SalesStats.vue';
    import BarChart from '@/components/BarChart.vue';
    import PieChart from '@/components/PieChart.vue';
    import { mapGetters, mapActions } from 'vuex';

    import * as Keys from '@/utils/keys';

    export default {
        name: 'StatsView',
        components: {
            BarChart,
            SalesStats,
            PieChart,
        },
        data() {
            return {
                graphTitleSize: 24,

                //selectedPeriod: null,
                selectedPeriodIndex: -1,
                //selectedSellerUuid: null,

                curPeriodCommissionPerCustomer: null,
                curPeriodCommissionPerProduct: null,

                saleValuePerMonth: null,
                commissionPerMonth: null,
                monthlyValuePerCustomer: null,
                monthlyValuePerProduct: null,
                monthlyCommissionPerCustomer: null,
                monthlyCommissionPerProduct: null,
                periodNames: null,

                fetchDashboardDataCancelToken: null,
            };
        },
        methods: {
            ...mapActions("period", ["changePeriodSaleValue", "changePeriodLadderProgress"]),
            async fetchStatisticsData(sellerUuid, period) {
                try {
                    if (!sellerUuid) {
                        return;
                    }
                    if (!period) {
                        return;
                    }

                    // Cancel the previous request if it exists
                    if (this.axiosCancelTokenSource) {
                        this.axiosCancelTokenSource.cancel("Operation canceled due to new request.");
                    }

                    this.axiosCancelTokenSource = axios.CancelToken.source();

                    const response = await axios.get("/api/get_dashboard_data", {
                        cancelToken: this.axiosCancelTokenSource.token,
                        params: {
                            "seller_uuid": sellerUuid,
                            "period": period.start,
                        }
                    });

                    // This should prevent the wrong period's stats from showing up if period was swapped before response was received
                    if (period.start != this.selectedPeriod.start) {
                        return;
                    }
                    this.saleValuePerMonth = response.data.sale_value_per_month;
                    this.commissionPerMonth = response.data.commission_per_month;
                    this.monthlyValuePerCustomer = response.data.monthly_value_per_customer;
                    this.monthlyCommissionPerCustomer = response.data.monthly_commission_per_customer;
                    this.monthlyValuePerProduct = response.data.monthly_value_per_product;
                    this.monthlyCommissionPerProduct = response.data.monthly_commission_per_product;

                    this.periodNames = response.data.period_names;
                    this.periodStarts = response.data.period_starts;

                    const selectedPeriodIndex = response.data.selected_period_index;
                    this.selectedPeriodIndex = selectedPeriodIndex;
                    this.curPeriodCommissionPerCustomer = this.monthlyCommissionPerCustomer[selectedPeriodIndex];
                    this.curPeriodCommissionPerProduct = this.monthlyCommissionPerProduct[selectedPeriodIndex];
                    const thisMonthSaleValue = this.saleValuePerMonth[selectedPeriodIndex];
                    this.changePeriodSaleValue(thisMonthSaleValue);
                    const ladderProgress = response.data[Keys.KEY_LADDER_PROGRESS];
                    this.changePeriodLadderProgress(ladderProgress);

                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                    } else {
                        console.error('Failed to fetch dashboard data:', error);
                        this.$toastr.warning("Error fetching data");
                    }
                }
            },
            async fetchPeriodSummary() {
                if (this.selectedPeriod == null) {
                    return;
                }
                if (this.$refs.salesStatsRef == null) {
                    return
                }

                this.$refs.salesStatsRef.fetchStats(this.selectedSellerUuid, this.selectedPeriod.start);
            },
            async doRefresh() {
                this.fetchStatisticsData(this.selectedSellerUuid, this.selectedPeriod);
                this.fetchPeriodSummary();
            },
        },
        computed: {
            ...mapGetters("period", ["selectedSellerUuid", "selectedPeriod", "refreshTrigger", "selectedPeriodMonthName"]),
        },
        watch: {
            selectedSellerUuid() {
                this.doRefresh();
            },
            selectedPeriod() {
                this.doRefresh();
            },
            refreshTrigger() {
                this.doRefresh();
            }
        },
        mounted() {
            this.doRefresh();
        }
    }
</script>
<style lang="scss">
    @import "@/assets/styles/StatsView";
</style>
