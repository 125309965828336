<template>
    <div class="chart_card tp_card">
        <h3 class="chart_title">
            {{title}}
        </h3>
        <h3
            class="chart_subtitle"
            >
            {{subtitle}}
        </h3>
        <!--
        <h3
            class="chart_total"
            v-html=totalValue
            >
        </h3>-->
        <div v-if="!hasData" class="no_data_container">
            <p>No data for this period</p>
        </div>
        <div class="inner_chart_container">
            <Doughnut
            id="commissions_bar_chart"
            :options="chartOptions"
            :data="chartData"
            />
        </div>
    </div>
</template>

<script>
    import { Doughnut } from 'vue-chartjs'
    import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
    import { strToColor, GRAPH_TEXT_COLOR_DARK_MODE, GRAPH_TEXT_COLOR_LIGHT_MODE } from "@/utils/graphs.js"
    import { formatAmount } from "@/utils/general.js"
    import { mapGetters } from 'vuex';

    ChartJS.register(ArcElement, Tooltip, Legend);

    export default {
        name: 'PieChart',
        components: { Doughnut },
        props: {
            data: Object,
            labels: Array,
            title: String,
            subtitle: String,
            selectedPeriod: Object,
            doColorTotal: Boolean,
        },
        data() {
            return {
                chartOptions: this.getChartOptions(),
                /*
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    color: this.isDarkMode ? GRAPH_TEXT_COLOR_DARK_MODE : GRAPH_TEXT_COLOR_LIGHT_MODE, // font color
                    plugins: {
                    }
                },*/
            }
        },
        methods: {
            getChartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    //borderColor: "#ff0000ff",
                    borderWidth: 0,
                    color: this.isDarkMode ? GRAPH_TEXT_COLOR_DARK_MODE : GRAPH_TEXT_COLOR_LIGHT_MODE,
                    plugins: {
                        // Your plugins configuration
                    }
                };
            }
        },
        watch: {
            isDarkMode: {
                handler() {
                    this.chartOptions = this.getChartOptions();
                },
                immediate: true, // This ensures the watcher is triggered after the component is mounted
            }
        },
        computed: {
            ...mapGetters('theme', ['isDarkMode']),
            hasData() {
                return this.data == null || Object.keys(this.data).length > 0;
            },
            chartData() {
                if (this.data == null) {
                    return {
                        labels: [],
                        datasets: [ { data : [] }  ],
                    }
                }
                var values = [];
                var keys = [];
                var colors = [];
                for (const [key, value] of Object.entries(this.data)) {
                    if (value == 0) {
                        continue;
                    }
                    keys.push(key);
                    colors.push(strToColor(key));
                    values.push(value);
                }

                return {
                    labels: keys,
                    datasets: [{
                        data: values,
                        backgroundColor: colors,
                    }]
                }
            },
            totalValue() {
                var total = 0;
                if (this.data != null) {
                    for (const key of Object.keys(this.data)) {
                        total += this.data[key];
                    }
                }
                return formatAmount(
                    {
                        amount: total,
                        roundByThousand: true,
                        doColor: this.doColorTotal,
                        hideZero: false,
                    }
                )
            }
        },
    }
</script>
