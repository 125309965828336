<template>
    <div class="chart_card tp_card">
        <h3 class="chart_title">
            {{title}}
        </h3>
        <h3
            class="chart_subtitle"
            >
            {{subtitle}}
        </h3>
        <!--<h3
            class="chart_total"
            v-html=totalValue
            >
        </h3>-->
        <div class="inner_chart_container">
            <Bar
            :options="chartOptions"
            :data="chartData"
            />
        </div>
    </div>
</template>

<script>
    import { Bar } from 'vue-chartjs'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    import { strToColor, GRAPH_TEXT_COLOR_DARK_MODE, GRAPH_TEXT_COLOR_LIGHT_MODE } from "@/utils/graphs.js"
    import { formatAmount } from "@/utils/general.js"
    import { mapGetters } from 'vuex';

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'BarChart',
        components: { Bar },
        props: {
            data: Array,
            labels: Array,
            test: String,
            title: String,
            subtitle: String,
            selectedPeriodIndex: Number,
            doColorTotal: Boolean,
        },
        data() {
            return {
                total: 0,
                chartOptions: this.getChartOptions(),
            }
        },
        methods: {
            getChartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    // backgroundColor: "#ffff00",
                    borderColor: "#ff0000",
                    color: this.isDarkMode ? GRAPH_TEXT_COLOR_DARK_MODE : GRAPH_TEXT_COLOR_LIGHT_MODE,
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: false,
                                color: "#888",
                            },
                        },
                    }
                }
            }
        },
        watch: {
            isDarkMode: {
                handler() {
                    this.chartOptions = this.getChartOptions();
                },
                immediate: true, // This ensures the watcher is triggered after the component is mounted
            }
        },
        computed: {
            ...mapGetters('theme', ['isDarkMode']),
            chartData() {
                if (this.data == null) {
                    return {
                        labels: [],
                        datasets: [ { data : [] }  ],
                    }
                }
                var allKeys = new Set();
                this.data.forEach(function (periodData) {
                    // totalCommission += self.commissionOfSaleItem(item, false);
                    var periodKeys = Object.keys(periodData);
                    periodKeys.forEach(function (key) {
                        allKeys.add(key);
                    });
                });
                var self = this;
                var datasets = [];
                allKeys.forEach(function (key) {
                    var keyData = [];
                    self.data.forEach(function (periodData) {
                        if (!periodData[key]) {
                            keyData.push(0);
                            return;
                        }
                        var value = periodData[key];
                        keyData.push(value);
                    });
                    const keyColor = strToColor(key);
                    datasets.push(
                        {
                            label: key,
                            data: keyData,
                            backgroundColor: keyColor,
                            // borderColor: "#ff0000",
                            borderWidth: 0,
                        }
                    )

                });

                return {
                    labels: this.labels,
                    datasets: datasets,
                }
            },
            totalValue() {
                if (this.data == null) {
                    return "";
                }
                var total = 0;
                const selectedPeriodData = this.data[this.selectedPeriodIndex];
                const periodKeys = Object.keys(selectedPeriodData);
                periodKeys.forEach(function (key) {
                    total += selectedPeriodData[key];
                });

                return formatAmount(
                    {
                        amount: total,
                        roundByThousand: true,
                        doColor: this.doColorTotal,
                        hideZero: true,
                    }
                )
            }
        },
    }
</script>
