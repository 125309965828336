<template>
    <div
        class="sale_stats_card bonus_stats_card tp_card"
        v-loading="periodLadderProgress === null"
    >
        <div class="bonus_card_upper">
            <h5 v-if="currentBonus?.has_data">
                {{ periodContract?.[Keys.KEY_LADDER_LOCKS_COMMISSION] ? "Commission" : "Bonus" }}
                level {{ currentBonus?.i_level + 1 }} / {{ periodContract?.bonus_levels?.length }}
            </h5>
            <h5 v-else>
                Bonus
            </h5>
        </div>
        <div v-if="currentBonus?.has_data" class="bonus_card_data_container">
            <div class="bonus_card_mid">
                <div class="bonus_level_container">
                    <p class="bonus_level_title">Current level</p>
                    <p class="bonus_level_rate">{{ Math.round(currentBonus?.rate) }}%</p>
                </div>
                <div v-show="currentBonus?.next_level_rate != null" class="bonus_level_container">
                    <p class="bonus_level_title">Next level</p>
                    <p class="bonus_level_rate">{{ Math.round(currentBonus?.next_level_rate) }}%</p>
                </div>
            </div>
            <div class="bonus_card_lower">
                <p class="bonus_progress_remaining" v-html="progressRemaining"></p>
                <div class="bonus_progress_bar_container">
                    <div class="progress">
                        <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{width: currentBonus?.percent + '%'}"
                            :aria-valuenow="currentBonus?.percent"
                            aria-valuemin="0"
                            aria-valuemax="100">
                            {{ Math.round(currentBonus?.percent) }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no_bonus_data_container" v-else>
            <p class="no_bonus_data_msg">No bonus data specified.</p>
            <p class="no_bonus_data_msg">Change
                <router-link to="/contracts">your contract</router-link>
                to activate bonus
            </p>
        </div>
    </div>

</template>
<script>
import {formatAmount} from "@/utils/general.js"
import {mapGetters} from 'vuex';

import * as Keys from '@/utils/keys.js';

export default {
    components: {},
    props: {
        title: String,
        icon: String,
        stats: Object,
    },
    data() {
        return {
            Keys,
        };
    },
    computed: {
        ...mapGetters("period", ["currentBonus", "periodContract", "periodLadderProgress"]),
        formatCommission() {
            if (this.stats == null) {
                return "";
            }
            return formatAmount({
                amount: this.stats.period_commission,
                roundByThousand: true,
                doColor: true,
                hideZero: false,
            });
        },
        formatSaleValue() {
            if (this.stats == null) {
                return "";
            }
            return formatAmount({
                amount: this.stats.period_sale_value,
                roundByThousand: true,
                doColor: false,
                hideZero: false,
            });
        },
        progressRemaining() {
            if (this.currentBonus == null || this.currentBonus.next_level_start == null) {
                return "";
            }
            const saleValue = formatAmount({
                amount: this.periodLadderProgress,
                roundByThousand: true,
                doColor: false,
                hideZero: false,
                hideCurrency: true,
            });
            const nextLevel = formatAmount({
                amount: this.currentBonus.next_level_start,
                roundByThousand: true,
                doColor: false,
                hideZero: false,
                hideCurrency: true,
            });
            return `${saleValue}/${nextLevel}`;
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/SalesStats.scss";
</style>
