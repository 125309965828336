<template>
    <div class="header">
        <div class="header_left_side">
            <div class="mobile_menu_btn_container">
                <i
                    class="fa-solid"
                    @click="onNavBtnClicked"
                    :class="{'fa-bars': !navIsOpen, 'fa-xmark': navIsOpen}"
                >
                </i>
            </div>
            <div class="current_page_name">
                {{this.$route.name}}
            </div>
        </div>
        <div class="mobile_logo_container">
            <img v-show="isDarkMode" class="logo" alt="TinPil logo" src="../assets/logos/tinpil_white.png">
            <img v-show="!isDarkMode" class="logo" alt="TinPil logo" src="../assets/logos/tinpil_original.png">
        </div>
        <!--<div class="header_bottom_shadow"></div>-->
        <div class="notification_button_container">
            <i
                class="toggle_aside_btn fa-regular fa-calendar"
                @click="onAsideBtnClicked">
            </i>
            <i class="fa-regular fa-moon" @click="toggleTheme"></i>
            <!--
            <div class="theme_selector_container form-check form-switch">
                <label
                    class="form-check-label"
                    for="themeSwitch"
                >
                    <i class="fa-regular fa-moon"></i>
                </label>
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="themeSwitch"
                    @change="toggleTheme"
                    :checked="isDarkMode"
                >
            </div>-->
            <NotificationsPanel />
        </div>
    </div>
</template>

<script>
    import NotificationsPanel from '@/components/NotificationsPanel.vue';
    import {mapGetters} from "vuex";
    //import Popper from "vue3-popper";
    export default {
        name: 'AppHeader',
        components: {
            //Popper,
            NotificationsPanel,
        },
        data() {
            return {
            };
        },
        props: {
            navIsOpen: {
                required: true,
                default: false,
            },
        },
        methods: {
            /*
            toggleTheme() {
                this.isDarkMode = !this.isDarkMode;
                const newTheme = this.isDarkMode ? 'dark' : 'light';
                document.body.setAttribute('data-theme', newTheme);
                localStorage.setItem('theme', newTheme);
            },*/
            onNavBtnClicked() {
                this.$emit("nav-btn-clicked");
            },
            onAsideBtnClicked() {
                this.$emit("aside-btn-clicked");
            },
            toggleTheme() {
                this.$store.dispatch('theme/toggleTheme');
                //this.$emit('on-toggle-theme');
            },
        },
        computed: {
            ...mapGetters('theme', ['isDarkMode']),
        },
        mounted() {
            //document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
        },
    };
</script>


<style lang="scss">
    .header {
        //@include shadow_bottom;
        @include panel;
        // border: 1px solid transparent;
        border-bottom: $panel_border_width solid var(--panel_border_color);

        // Bottom only shadow. Tweaked to achieve a similar enough look to the other shadows
        //box-shadow: 0px 10px $shadow_blur_radius calc($shadow_blur_radius*-1) var(--card_shadow_color);
        display: flex;
        justify-content: end;
        align-items: center;
        h1 {
            margin: 1rem;
        }
        .header_left_side {
            .current_page_name {
                color: var(--faded_text_color);
                text-transform: uppercase;
                @include mobile {
                    display: none;
                }
            }
            .mobile_menu_btn_container {
                margin: 0;
                font-size: 1.5rem;
                @include tablet_and_bigger {
                    display: none;
                }
            }
        }
        .mobile_logo_container {
            height: 100%;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            img {
                max-height: 100%;
                width: 100%;
            }
            margin: auto;
            @include tablet_and_bigger {
                display: none;
            }
        }
        .theme_selector_container {
            // margin-right: 2rem;;
        }

        .notification_button_container {
            display: flex;
            align-items: center;
            margin: 0;
            font-size: 1.5rem;
            height: 100%;
            padding-right: $lesser_component_padding;
            gap: $lesser_component_padding;
            .theme_selector_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0;
                font-size: 1.0rem;
                input {
                    border: 2px solid var(--faded_text_color);
                    margin: 0;
                }
                label {
                    i {
                        font-size: 1.2rem;
                    }
                }
            }
            .toggle_aside_btn {
                @include desktop {
                    display: none;
                }
            }
            i {
                padding: 0.5rem;
                border-radius: 50%;
                cursor: pointer;

                &:hover {
                    background: var(--faded_text_color);
                }
            }
        }
    }
</style>
