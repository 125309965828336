import { createApp, h } from 'vue';

export default {
    mounted(el, binding) {
        if (binding.value) {
            addSpinner(el);
        }
    },
    updated(el, binding) {
        if (binding.value) {
            addSpinner(el);
        } else {
            removeSpinner(el);
        }
    }
};

function addSpinner(el) {
    // Prevent duplicate spinner
    if (el.querySelector('.loading-spinner')) return;

    const spinner = createApp({
        render: () => h('div', { class: 'loading-spinner' })
    });

    const spinnerContainer = document.createElement('div');
    spinnerContainer.classList.add('loading-spinner-container');

    spinner.mount(spinnerContainer);
    el.classList.add('loading-parent');
    el.appendChild(spinnerContainer);
}

function removeSpinner(el) {
    const spinner = el.querySelector('.loading-spinner-container');
    if (spinner) {
        spinner.remove();
    }
    el.classList.remove('loading-parent');
}
/*
export default {
    mounted(el, binding) {
        if (binding.value) {
            addLoadingDots(el);
        }
    },
    updated(el, binding) {
        if (binding.value) {
            addLoadingDots(el);
        } else {
            removeLoadingDots(el);
        }
    }
};

function addLoadingDots(el) {
    // Prevent duplicate loading indicator
    if (el.querySelector('.loading-dots-container')) return;

    const loadingDots = createApp({
        render: () =>
            h('div', { class: 'loading-dots-container' }, [
                h('div', { class: 'loading-dot' }),
                h('div', { class: 'loading-dot' }),
                h('div', { class: 'loading-dot' })
            ])
    });

    const container = document.createElement('div');
    container.classList.add('loading-dots-container');

    loadingDots.mount(container);
    el.classList.add('loading-parent');
    el.appendChild(container);
}

function removeLoadingDots(el) {
    const dots = el.querySelector('.loading-dots-container');
    if (dots) {
        dots.remove();
    }
    el.classList.remove('loading-parent');
}
 */