export const KEY_TYPE = "type";
export const KEY_PERIOD_START = "period_start";
export const KEY_NAME = "name";
export const KEY_PERIOD_STATUS = "period_status";
export const KEY_WEEK_START = "week_start";

export const KEY_EMAIL = "email";
export const KEY_PASSWORD = "password";

export const KEY_UUID = "uuid";

export const KEY_EXECUTION_AT = "execution_at";
export const KEY_SOLD_AT = "sold_at";
export const KEY_SALE_UUID = "sale_uuid";
export const KEY_SALE_FE_UUID = "sale_fe_uuid";

export const KEY_APPROVED_BY_SELLER = "approved_by_seller";
export const KEY_APPROVED_BY_MANAGER = "approved_by_manager";

export const KEY_LADDER_BASE_TYPE = "ladder_base_type";
export const KEY_LADDER_LOCKS_COMMISSION = "ladder_locks_commission";
export const KEY_LADDER_RETROACTIVE = "ladder_retroactive";
export const KEY_ADDONS_COUNT_ON_LADDER = "addons_count_on_ladder";
export const KEY_LADDER_AFFECTS_ADDONS = "ladder_affects_addons";

export const KEY_COMMISSION_DELAY_LOCKED = "commission_delay_locked";
export const KEY_COMMISSION_DELAY = "commission_delay";

export const KEY_MANAGERS_CAN_VIEW_MANAGERS = "managers_can_view_managers";

export const KEY_HAS_LOCKED_COMMISSION = "has_locked_commission";
export const KEY_COMMISSION_RATE_LOCKED = "commission_rate_locked";
export const KEY_HAS_OUTDATED_SETTINGS = "has_outdated_settings";

export const KEY_LADDER_PROGRESS = "ladder_progress";

export const KEY_FIRST_NAME = "first_name";
export const KEY_LAST_NAME = "last_name";
export const KEY_FULL_NAME = "full_name";
export const KEY_USER_UUID = "user_uuid";
export const KEY_ROLE = "role";
export const KEY_HAS_HR_ACCESS = "has_hr_access";
export const KEY_IS_ORG_ADMIN = "is_organization_admin";
export const KEY_PROFILE_PIC = "profile_pic"

export const KEY_PERIOD_COMMISSION = "period_commission";
export const KEY_PERIOD_COMMISSION_EARNED = "period_commission_earned";
export const KEY_PERIOD_N_NEW = "period_n_new";
export const KEY_PERIOD_N_CUSTOMERS = "period_n_customers";
export const KEY_PERIOD_SALE_VALUE = "period_sale_value";
export const KEY_CURRENT_PERIOD = "current_period";
export const KEY_YTD = "ytd";

export const KEY_COMMISSION_TYPE = "commission_type";

export const KEY_HAS_BONUS = "has_bonus";

export const KEY_EXPIRES_AT = "expires_at";

export const KEY_CODE = "code";
export const KEY_ERROR = "error";
export const KEY_STATE = "state";

export const KEY_ERROR_MSG = "error_msg";
export const KEY_EDIT = "edit";

export const KEY_AUTH_URL = "auth_url";

export const KEY_HAS_FORTNOX_INTEGRATION = "has_fortnox_integration";
export const KEY_IS_FORTNOX_LINKED = "is_fortnox_linked";
export const KEY_FORTNOX_COMPANY_NAME = "fortnox_company_name";
export const KEY_FORTNOX_ORG_NUMBER = "fortnox_org_number";
export const KEY_FORTNOX_EMPLOYEE_ID = "fortnox_employee_id";
export const KEY_FORTNOX_SYNC_OK = "fortnox_sync_ok";
export const KEY_FORTNOX_SYNC_ERROR = "fortnox_sync_error";
export const KEY_FORTNOX_CLEARED = "fortnox_cleared";
// export const KEY_N_FORTNOX_ERRORS = "n_fortnox_errors";

export const KEY_IS_MANAGERS_PERIOD = "is_managers_period";

export const KEY_EXTENDED_INFO = "extended_info";
export const KEY_IS_LICENSE_ACTIVE = "is_license_active";
export const KEY_N_LICENSES = "n_licenses";
export const KEY_IS_ACTIVATED = "is_activated";

export const KEY_SCOREBOARD_SELLERS_DATA = "scoreboard_sellers_data";
export const KEY_TEAM_SALE_VALUE = "team_sale_value";
export const KEY_TEAM_N_SALES = "team_n_sales";
export const KEY_N_SALES = "n_sales";

export const KEY_SALE_VALUE = "sale_value";
export const KEY_RECENT_SALES_DATA = "recent_sales_data";
