<template>
    <div class="stage_container"
         :class="ballContainerClass"
    >
        <div
            class="ball_container"
        >
            <!--
            <i
                class="fa-solid"
                :class="whichIcon"
            ></i>-->
            <!--
            <i
                class="bi"
                :class="whichIcon"
            ></i>-->
            <Check v-if="isDone && !isLastStage" />
            <LockKeyhole v-if="isLastStage && stageNumber === currentStage" />
            <div v-if="isCurrent && !isLastStage" class="dot"></div>
        </div>
        <div class="stage_info_container">
            {{stageName}}
        </div>
    </div>
</template>
<script>
    // import { mapActions, mapGetters } from 'vuex';

    import * as Keys from '@/utils/keys.js';
    import * as Const from '@/utils/const.js';

    import { Check,  LockKeyhole } from 'lucide-vue-next';

    export default {
        components: {
            Check,
            //Lock,
            LockKeyhole,
        },
        props: {
            stageNumber: {
                type: Number,
                default: 0,
                required: true,
            },
            currentStage: {
                type: Number,
                default: 0,
                required: true,
            },
            stageName: {
                type: String,
                default: "",
                required: true,
            },
            isLastStage: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data() {
            return {
                Keys,
                Const,
            };
        },
        methods: {
        },
        computed: {
            ballContainerClass() {
                if (this.stageNumber < this.currentStage) {
                    return "past_stage";
                } else if (this.stageNumber === this.currentStage) {
                    let className = "current_stage";
                    if (this.isLastStage) {
                        className += " last_stage";
                    }
                    return className;
                } else {
                    return "future_stage";
                }
            },
            isDone() {
                return this.stageNumber < this.currentStage;
            },
            isCurrent() {
                return this.stageNumber === this.currentStage;
            },
            isFuture() {
                return this.stageNumber > this.currentStage;
            },
        },
        watch: {
            selectedSellerUuid() {
                this.fetchPeriodStatus();

                this.periodActionComplete = false;
            },
        }
    };
</script>

<style lang="scss" scoped>
.stage_container {
    display: flex;
    flex: 1;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    .ball_container {
        $size: 1.8rem;
        background: $green;
        width: $size;
        height: $size;
        border-radius: 50%;
        transition: background $transition_delay ease-in-out;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: $size;
        svg {
            color: white;
            width: 60%;
            height: 60%;
        }
        .dot {
            border-radius: 50%;
            width: 30%;
            height: 30%;
            background: var(--white);
        }
        /*
        .stage_info_container {
            position: absolute;
            bottom: -100%;
            //-ms-transform: translate(-50%, 0%);
            //transform: translate(-50%, 0%);
            background: green;
        }*/
    }
    &.past_stage {
        .ball_container {
            background: $green;
        }
    }
    &.current_stage {
        .ball_container {
            background: var(--primary_brand_color);
        }
        &.last_stage {
            .ball_container {
                background: $green;
            }
        }
    }
    &.future_stage {
        .ball_container {
            background: var(--white);
            border: 2px solid var(--faded_text_color);
        }
    }
}

</style>
