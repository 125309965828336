<template>
    <div :class="{'is-visible': isVisible}" class="nav_sidebar">
        <div class="logo_container">
            <img v-show="isDarkMode" class="logo" alt="TinPil logo" src="../assets/logos/tinpil_white.png">
            <img v-show="!isDarkMode" class="logo" alt="TinPil logo" src="../assets/logos/tinpil_original.png">
        </div>
        <!--
        <div class="d-flex justify-content-center">
            <div class="theme_selector_container form-check form-switch">
                <label
                    class="form-check-label"
                    for="themeSwitch"
                    >
                    <i class="fa-regular fa-moon"></i>
                </label>
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="themeSwitch"
                    @change="toggleTheme"
                    :checked="isDarkMode"
                    >
            </div>
        </div>-->
        <!--
        <div v-if="userData" class="user_data_container">
                <div class="profile_picture_container">
                    <img v-if="userData.profile_pic_data" alt="Profile picture" :src="userData.profile_pic_data">
                    <b-avatar
                        v-else
                        :text="userData.initials"
                        text-size="rem"
                        size="7rem"
                        :style="{background: userData?.user_color + '!important'}"
                        >
                    </b-avatar>
                </div>
                <p class="text-center users_name"><strong>{{ userData.full_name }}</strong></p>
                <p class="text-center">
                <strong> {{ Const.ROLE_TO_FE_ROLE[userData.role] }} </strong>
                at
                <strong>{{ userData.organization_name }}</strong>
                </p>
                <p v-show = "userData?.is_organization_admin" class="text-center">Admin</p>
        </div>-->
        <div class="nav_lists_container">
            <ul class="primary_pages_list">
                <li
                    v-for="page in accessiblePages"
                    :key="page.path"
                    class="nav_item"
                >
                    <router-link
                        :to="page.path"
                        active-class="router_link_active"
                        @click="navItemClicked"
                        >
                        <i :class="['fa-solid', page.icon]"></i>
                        <span>{{ page.name }}</span>
                    </router-link>
                </li>
            </ul>
            <div class="nav_bottom_container">
                <div v-if="userData?.feedback_link?.length > 0" class="feedback_link_container">
                    <a class="feedback_link" :href="userData?.feedback_link" target="_blank">Feedback</a>
                </div>
                <ul class="secondary_pages_bar">
                    <li class="cursor_pointer nav_item" @click="doLogout">
                        <a>
                            <i class="fa-solid fa-right-from-bracket"></i>
                            <span>Log out</span>
                        </a>
                        <hr class="hr hr-blurry"/>
                    </li>
                    <!--<li>
                        <router-link to="/settings" active-class="router_link_active">
                            <i class="fa-solid fa-cog"></i>
                            <span>Settings</span>
                        </router-link>
                    </li>-->
                </ul>

                <router-link
                    to="/settings"
                    active-class="router_link_active"
                    class="user_data_container"
                    @click="navItemClicked"
                >
                    <UserAvatar
                        class="user_avatar"
                        :picData="userData?.[Keys.KEY_PROFILE_PIC]"
                        :firstName="userData?.[Keys.KEY_FIRST_NAME]"
                        :lastName="userData?.[Keys.KEY_LAST_NAME]"
                        :userUuid="userData?.[Keys.KEY_USER_UUID]"
                        size="3rem"
                    >
                    >
                    </UserAvatar>
                    <div class="user_info_section">
                        <p class="users_name">{{ userData.full_name }}</p>
                        <p class="role_container">
                            <span class="role">{{ Const.ROLE_TO_FE_ROLE[userData.role] }}</span>
                            <span v-show="userData?.is_organization_admin" class="role"> & Admin</span>
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    //import axios from 'axios';
    import { mapGetters, mapActions } from 'vuex';

    import UserAvatar from '@/components/UserAvatar.vue';

    import * as Const from '@/utils/const';
    import * as Keys from '@/utils/keys';

    export default {
        components: {
            UserAvatar,
        },
        data() {
            return {
                Const,
                Keys,
                // TODO should probably use a shared list with router/index.js
                navItems: [
                    { name: "Dashboard", path: "/dashboard", icon: "fa-square-poll-vertical", requires_admin: false, requires_hr: false},
                    //{ name: "Dashboard", path: "/stats", icon: "fa-square-poll-vertical", requires_admin: false, requires_hr: false},
                    { name: "Sales", path: "/sales", icon: "fa-dollar-sign", requires_admin: false, requires_hr: false},
                    { name: "Contracts", path: "/contracts", icon: "fa-file-invoice-dollar", requires_admin: false, requires_hr: false},
                    { name: "Company", path: "/organization", icon: "fa-building", requires_admin: false, requires_hr: true},
                    { name: "Payroll", path: "/payroll", icon: "fa-receipt", requires_admin: false, requires_hr: true},
                    { name: "Scoreboard", path: "/scoreboard", icon: "fa-trophy", requires_admin: false, requires_hr: false},
                ],
                isVisible: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['userData']),
            ...mapGetters('theme', ['isDarkMode']),
            accessiblePages() {
                if (this.userData == null) {
                    return [];
                }
                const pages = [];
                let self = this;
                this.navItems.forEach(function (navItem) {
                    if (navItem.requires_admin && !self.userData[Keys.KEY_IS_ORG_ADMIN]) {
                        return;
                    }
                    if (navItem.requires_hr && !self.userData[Keys.KEY_HAS_HR_ACCESS]) {
                        return;
                    }
                    pages.push(navItem);
                });
                return pages;
            },
            /*
            isDarkMode() {
                return this.$store.getters['theme/isDarkMode'];
            }*/
        },
        methods: {
            ...mapActions("auth", ["logout"]),
            doLogout() {
                this.logout({ router: this.$router });
            },
            ...mapActions("auth", ["refreshToken"]),
            ...mapActions("auth", ["whoami"]),
            ...mapActions("auth", ["clearUserData"]),
            async testApi() {
                //this.logout({ router: this.$router });
                this.clearUserData();
                /*
                const response = await axios.post("/api/test", {
                    // "sale_data": sale,
                    //"seller_uuid": this.selectedSellerUuid,
                });
                console.log("response: ", response);*/
            },
            navItemClicked() {
                this.$emit("nav-item-clicked");
            },
        },
        mounted() {
        },
    }
</script>

<style lang="scss">

.nav_sidebar {
    //@include shadow;
    color: var(--primary_text_color);
    height: 100%;
    border-right: $panel_border_width solid var(--panel_border_color);


    .logo_container {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            width: 70%;
        }
    }
    .nav_lists_container {
        .primary_pages_list, .secondary_pages_bar {
            .nav_item a.router_link_active, .nav_item button {
                background: var(--primary_background_color);
                i {
                    color: var(--primary_brand_color) !important;
                }
                span {
                    /*font-weight: $bold_weight;*/
                }
            }
            .nav_item {
                height: 100%;
                width: 100%;
                color: var(--primary_text_color);
                i {
                    font-size: 1.5rem;
                }
                a, button {
                    text-decoration: none;
                    color: var(--primary_text_color);
                }
                a {
                    display: flex;
                    justify-content: start;
                    margin-left: 10%;
                    width: 80%;
                    //margin-left: 5%;
                    border-radius: $default_border_radius;
                    color: var(--primary_text_color);
                    align-items: center;
                    $padding: 0.1rem;
                    padding-top: $padding;
                    padding-bottom: $padding;
                    margin-top: 0.3rem;
                    span {
                        font-size: 1.3rem;
                        //font-weight: $bold_weight;
                        margin-left: $lesser_component_padding;
                    }
                    i {
                        flex-basis: 2rem;
                        text-align: center;
                    }
                }
                hr {
                    width: 80%;
                    margin-left: 10%;
                }
            }
            li a {
                span {
                }
                &:hover, &.router_link_active {
                    background: var(--secondary_background_color) !important;
                }
            }
        }

        .primary_pages_list {
            list-style: none;
            padding: 0;
            margin-top: $inner_component_padding;

        }
        .nav_bottom_container {
            width: 100%;
            position: absolute;
            bottom: 0;
            .feedback_link_container {
                padding: 2rem;
                .feedback_link {
                    border-radius: $default_border_radius;
                    border: 2px solid var(--primary_text_color);
                    text-decoration: none;
                    text-align: center;
                    display: block;
                    color: var(--primary_text_color);
                    font-size: 1.5rem;
                    &:hover {
                        color: var(--faded_text_color);
                    }
                }
            }
            li:hover {
                //background: var(--primary_background_color) !important;
            }
            .secondary_pages_bar {
                display: flex;
                padding: 0;
                list-style: none;
                margin-bottom: 0;
                li {
                    a {
                        display: flex;
                        //padding: 1rem;
                        flex-direction: row;
                        align-items: center;
                        i {
                            //margin-left: $inner_component_padding;
                        }
                        span {
                            //margin-left: $lesser_component_padding;
                            //font-size: 1.2rem;
                        }
                    }
                }
            }
            .user_data_container {
                display: flex;
                //justify-content: center;
                padding-left: 10%;
                padding-bottom: $lesser_component_padding;
                text-decoration: none;
                color: var(--primary_text_color);
                .user_avatar {
                    //flex: 1;
                }
                .user_info_section {
                    //flex: 2;
                    margin-left: $lesser_component_padding;
                    p {
                        margin: 0;
                    }
                    .users_name {
                        font-size: 1.2rem;
                    }
                    .role {
                        color: var(--faded_text_color);
                    }
                }
            }
        }
    }
}

</style>
