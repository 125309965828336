<template>
    <div class="aside_panel">
        <h3>Period status</h3>
        <h5>Commission earned</h5>
        <p class="amount_earned"
            v-html="formatAmount({
                amount: periodEarned,
                roundByThousand: false,
                doColor: false,
                hideZero: false,
           })">
        </p>
        <PeriodStatus>

        </PeriodStatus>
    </div>
</template>

<script>
    //import axios from 'axios';
    import { mapGetters } from 'vuex';

    import * as Const from '@/utils/const';
    import * as Keys from '@/utils/keys';
    import {formatAmount} from "@/utils/general.js"

    import PeriodStatus from '@/components/PeriodStatus/PeriodStatus.vue';

    export default {
        data() {
            return {
                Const,
                Keys,
            };
        },
        components: {
            PeriodStatus,
        },
        computed: {
            ...mapGetters("auth", ["userData"]),
            ...mapGetters("theme", ["isDarkMode"]),
            ...mapGetters("period", ["periodEarned"]),
            /*
            isDarkMode() {
                return this.$store.getters['theme/isDarkMode'];
            }*/
        },
        methods: {
            formatAmount,
        },
        mounted() {
        },
    }
</script>

<style lang="scss">

.aside_panel {
    padding: $lesser_component_padding;
    position: fixed;
    right: 0;
    border-left: $panel_border_width solid var(--panel_border_color);
    h3 {
        font-size: 1.5rem;
        color: var(--faded_text_color);
    }
    h5 {
        color: var(--primary_text_color);
        font-size: 1.2rem;
        margin-top: $inner_component_padding;
    }
    .amount_earned {
        font-size: 2rem;
        color: var(--strong_highlight_color);
    }
}


</style>
